import React, { Component } from 'react'
import './Footer.css'
// import {Link} from 'react-router-dom'
// import { faMapMarker,faPhone,faEnvelope } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import logo from '../../Assets/Images/unipel logo.png';


class Footer extends Component {
    render() {
        return(
            <footer className="page-footer font-small " style={{backgroundColor: " #413938"}}>
              
              {/* <div className="text-center text-md-left pt-5 ml-5" >
            
                
                <div className="row my-3 mr-5"> */}
            
                  
                  {/* col-1 */}
                  
                  {/* <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            
                    
                    <h6 class="text-uppercase font-weight-bold">About</h6>
                    <hr class=" ft-hr mb-4 mt-0 d-inline-block mx-auto" style={{width: "40px"}} />
                    <p>
                      <Link to="/events" className="ft-link">Our Events</Link>
                    </p>
                    <p>
                      <Link to="/brands" className="ft-link">Our Brands</Link>
                    </p>
                    <p>
                      <Link to="/gallery" className="ft-link">Our Collections</Link>
                    </p>
                    <p>
                      <Link to="/about" className="ft-link">Our People</Link>
                    </p>
            
                  </div> */}

                  {/* col-1 alter */}

                  {/* <div className="col-md-4 col-lg-4 col-xl-2 mx-auto mb-4">
            
                    <div className="py-0">
                        <div className="mb-5">
                          <img src={logo} alt="logo"></img>
                        </div>

                        <p className="ft-links font-weight-bold ml-1">
                            <Link className="ft-link px-2" to="/">Home</Link>
                            |
                            <Link className="ft-link px-2" to="/gallery">Collections</Link>
                            |
                            <Link className="ft-link px-2" to="/about">About</Link>
                        </p>
                        
                    </div>
                    
                  
                  </div>


                  <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4 pl-3">
            
                    
                    <h6 className="ft-heading text-uppercase font-weight-bold">Unipel Corporation</h6>
                    <hr className=" ft-hr mb-4 mt-0 d-inline-block mx-auto" style={{width: "160px"}} />
                    <p className="ft-content">Unipel Corporation, established in the year 2001, has been a pioneering exporter of Leather Garments based in Chennai, India. Flexibility is our strength.With the skill and intuition to move swiftly to meet the market demands, Unipel Corporation has become one of an important force in the market</p>
            
                  </div>
                  
                  <div className="col-md-2 col-lg-2 col-xl-3  mb-md-0 mb-4" >
            
                    <h6 className="ft-heading text-uppercase font-weight-bold">Contact</h6>
                    <hr className="ft-hr mb-4 mt-0 d-inline-block mx-auto" style={{width: "70px"}} />
                    <div className="ft-content">
                      <p>
                      <FontAwesomeIcon icon={faMapMarker} className="mr-2" style={{color:"chocolate"}}/> No62, M.G.R. High Road, Nagelkeni, Chromepet,Chennai- 600044, India</p>
                      <p>
                      <FontAwesomeIcon icon={faEnvelope} className="mr-2" style={{color:"chocolate"}}/> info@unipel.in</p>
                      <p>
                      <FontAwesomeIcon icon={faPhone} className="mr-2" style={{color:"chocolate"}}/> +91 44 2238 5556 </p>
                      <p>
                      <FontAwesomeIcon icon={faPhone} className="mr-2" style={{color:"chocolate"}}/> +91 44 2238 1532</p>
                    </div>
                  </div>

                  
                  
            
                </div>
                
              </div> */}
              <div className="footer-copyright text-center py-3" style={{backgroundColor: "#2C2422"}}>© 2019 Copyright:
                 Unipel Corporation
              </div>
            </footer>
        );
    }
}

export default Footer;