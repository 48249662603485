//function importAll => source:
//https://stackoverflow.com/questions/42118296/dynamically-import-images-from-a-directory-using-webpack

const FetchImages = (dir) => {
	var images = null;

	function importAll(r){
		let images = {};
		r.keys().map((item, index) => { return images[item.replace('./', '')] = r(item); });
		return images;
	}

	switch(dir)
	{
		case "fallmen":
		  images = importAll(require.context('../../../Assets/Images/Gallery/fallmen',false, /\.(png|jpe?g|svg)$/));
		  break;
		case "fallwomen":
		  images = importAll(require.context('../../../Assets/Images/Gallery/fallwomen',false, /\.(png|jpe?g|svg)$/));
		  break;
		case "springmen":
		  images = importAll(require.context('../../../Assets/Images/Gallery/springmen',false, /\.(png|jpe?g|svg)$/));
		  break;
		case "springwomen":
		  images = importAll(require.context('../../../Assets/Images/Gallery/springwomen',false, /\.(png|jpe?g|svg)$/));
		  break;
		case "cards":
		  images = importAll(require.context('../../../Assets/Images/Gallery/cards',false, /\.(png|jpe?g|svg)$/));
		  break;
		default:
		  console.log("Error in dirname ",dir);
	};

	return images;
}

export default FetchImages;