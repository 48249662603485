import React, { Component } from 'react'
import './Content.css'
import { faQuoteLeft,faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



class Content extends Component {
    
    render() {
         const rectStyle = {
            background: `url(${this.props.img})`,
            backgroundSize: "cover",
            backgroundPosition: "center center"
         };
         
         const reverseStyle = this.props.reverse ? {flexDirection: "row-reverse"} : {flexDirection: "row"};

        return(
           <div>
                <div style={rectStyle} className="rectangle">
                    <div id="title-top">
                        <strong id="title">{this.props.title}</strong>
                    </div>
                    <div className="at-container" style={reverseStyle}>
                        <div className="img"> 
                            <div className="text">
                                <FontAwesomeIcon icon={faQuoteLeft} className="Quotes"/>
                                    {this.props.text}
                                <FontAwesomeIcon icon={faQuoteRight} className="Quotes"/>
                            </div>
                        </div>
                        <div className="dummy"></div>
                    </div>
                    {/* {this.props.reverse ? 
                    <div className="container">
                        <div className="img" style={{order: 2}}> 
                            <div  className="text">{this.props.text}</div>
                        </div>
                        <div className="dummy" style={{order: 1}}></div>
                    </div>
                    :
                    <div className="container">
                        <div className="img" > 
                            <div  className="text">{this.props.text}</div>
                        </div>
                        <div className="dummy"></div>
                    </div>
                    } */}
                </div>
           </div> 
        );
    }
}

export default Content;