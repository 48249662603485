import React, { Component } from 'react';
import { Route ,Switch} from "react-router-dom";

import './App.css';

import Home from './Components/Home/Home';
import About from './Components/About/About';
import Events from './Components/Event/Event';
import Brands from './Components/Brands/Brands';
import Gallery from './Components/Gallery/Gallery';
import CategoryItems from './Components/Gallery/CategoryItems/CategoryItems';
import Contact from './Components/Contactus/Contactus';

class App extends Component{
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render(){
    return(
      <div className="App">
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/about" component={About}></Route>
            <Route exact path="/events" component={Events}></Route>
            <Route exact path="/gallery" component={Gallery}></Route>
            <Route exact path="/gallery/:category" component={CategoryItems}/>
            <Route exact path="/brands" component={Brands}></Route>
            <Route exact path="/contact" component={Contact}></Route>
          </Switch>
      </div>
    );
  }
}

export default App;
