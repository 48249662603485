import React, { Component } from 'react'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import './About.css'
import Content from './Content'
import  A1 from '../../Assets/Images/About/A1.jpg'
import  A2 from '../../Assets/Images/About/A2.jpg'
import  A3 from '../../Assets/Images/About/A3.jpg'
import  A4 from '../../Assets/Images/About/A4.jpg'
import  A5 from '../../Assets/Images/About/A5.jpg'

class About extends Component{
    //constructor(props){
      //  super(props);
        //this.state= {
          //  reverse: false
        //}
        // this.toggle = this.toggle.bind(this)
    //}
    // toggle(){
    //     this.setState(prevState => ({
    //         reverse: !prevState.reverse
    //     }))
    //     console.log(this.state.reverse)
    //     return(this.state.reverse);
    // }
    componentDidMount() {
        document.body.scrollTop=0;
    }
    render(){
        let cards = [
            {
                title: "Our Story",
                text: " Unipel Corporation, established in the year 2001, has been a pioneering exporter of Leather Garments based in Chennai, India. With over two decades of experience in the leather fashion business we have established a unique position with our customers, suppliers and employees. Flexibility is our strength. With the skill and intuition to move swiftly to meet the market demands, Unipel Corporation has become one of an important force in the market. ",
                img: A1
            },
            {
                title: "Our People",
                text: " We are a people driven company. Our core strength is our people. We are built, equipped and managed to the high standards by a responsible and talented team of employees who has over two decades of quality experience. With over 150 extremely skilled work-force, and an in house Quality Control team with special assistance by Quality Control Technicians from Europe, our people are the major reason for our success. ",
                img: A2
            },
            {
                title: "Our Factory",
                text: " A BSCI certified factory, equipped with modern stitching machines along with a group of highly qualified specialists in the field, the factory has an installed capacity of 4000-5,000 garments per month. Plant and machinery is being continuously upgraded to keep pace with the rapid development in trade. Our Product development and R&D continuously strives to come out with niche products for our customers in various markets. ",
                img: A3
            },
            {
                title: "Our Products",
                text: " Since inception the company has been specializing in high quality leathers with finishes like Pure Aniline, Semi Aniline, Nubuck, Suede, Wax Treated, Garment Dyed, Garment Washed etc., along with Hand cut works, Application of beads, Studs, Hand Embroidery, Laser, Digital prints, Screen Prints, Tie & Dye and other appliqués that are being used in many of the high fashion brands in Europe. ",
                img: A4
            },
            {
                title: "Raw Material",
                text: " Associating with leading tanners, we handle all types of garment leathers with high standards. Besides domestic sources, with a wide network of raw material suppliers from other countries we are equipped to meet the high quality demands of the high fashion brands being catered to. ",
                img: A5
            }
        ]
        return(
            <div className="About">
                <Navbar></Navbar>
                <div className="At-inner">
                    {cards.map( (card, index) => 
                        <Content 
                            key={index} 
                            title={card.title} 
                            text={card.text} 
                            img={card.img}
                            reverse={index % 2 !== 0}
                        />
                    )}
                <Footer></Footer>
                </div>
            </div>
        );
    }
}

export default About;