import React from 'react';

import classes from './ImageCard.module.css';

const ImageCard = (props) => {
	return (
		<div className={classes.imgCard}>
			<div className={classes.close} onClick={()=>props.clicked()}> X </div>
			<img src={props.src} className={classes.cardImg} alt={props.alt} />
		</div>
	);
};

export default ImageCard;