import React from 'react';

import classes from './OutlineBtn.module.css';

const OutlineBtn = (props) => {
	return (
			<button className={classes.btnStyles} onClick={props.clicked} style={props.style}>
				{props.text}
			</button>
	);
};

export default OutlineBtn;