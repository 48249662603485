import React, { Component } from 'react'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import './Contactus.css'
import { faMapMarker,faPhone,faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class Contact extends Component{
    render(){    
        var mapStyle = {
            width: "70%", 
            height: "80%",  
            border: "0" 
               
        };  
        return(    
            <div className="contactUs">
                <Navbar></Navbar>
                <div className="ct-inner">
                <h1 className="ct-heading">Contact Us</h1>
                    {/* <GoogleMap /> */}

                    <div className="ct-mid-cards">
                        <div className="card text-center ct-mid-card">
                        <div className="card-body">
                            <FontAwesomeIcon icon={faMapMarker} className="ct-icon" style={{color:"chocolate"}}/>
                            <h3 className="card-title ct-card-title">ADDRESS</h3>
                            <p className="card-text">No.62, M.G.R. High Road, Nagalkeni, Chromepet, Chennai- 600044, India.</p>
                            
                        </div>
                        </div>

                        <div className="card text-center ct-mid-card">
                        <div className="card-body">
                            <FontAwesomeIcon icon={faEnvelope} className="ct-icon" style={{color:"chocolate"}}/>
                            <h3 className="card-title ct-card-title">EMAIL</h3>
                            <div className="card-text">info@unipel.in</div>
                            <div className="card-text">unipel@airtelmail.in</div>
                        </div>
                        </div>

                        <div className="card text-center ct-mid-card">
                        <div className="card-body">
                            <FontAwesomeIcon icon={faPhone} className="ct-icon" style={{color:"chocolate"}}/>
                            <h3 className="card-title ct-card-title">PHONE</h3>
                            <div className="card-text">+91 44 2238 5556</div>
                            <div className="card-text">+91 44 4207 6538</div>
                        </div>
                        </div>
                    </div>
                    
                
                    <hr className="ct-hr mb-4 mt-0 d-inline-block mx-auto" style={{width: "70%" ,height: "5px"}} />

                    {/* <div className="ct-mid">
                        <div className="ct-content">
                        <p><FontAwesomeIcon icon={faMapMarker} className="mr-2 " style={{color:"chocolate"}}/> No62, M.G.R. High Road, Nagelkeni, Chromepet,Chennai- 600044, India</p>
                        </div>
                        <hr className=" mb-4 mt-0 d-inline-block mx-auto" style={{width: "100%" , height: "1px", backgroundColor:"white"}} />
                        <div className="ct-content">
                        <p><FontAwesomeIcon icon={faEnvelope} className="mr-2" style={{color:"chocolate"}}/> info@unipel.in</p>
                        </div>
                        <hr className=" mb-4 mt-0 d-inline-block mx-auto" style={{width: "100%" ,height: "1px", backgroundColor:"white"}} />
                        <div className="ct-content">
                        <p>
                        <FontAwesomeIcon icon={faPhone} className="mr-2" style={{color:"chocolate"}}/> +91 44 2238 5556 </p>
                        <p>
                        <FontAwesomeIcon icon={faPhone} className="mr-2" style={{color:"chocolate"}}/> +91 44 2238 1532</p>
                        </div>
                    </div> */}

                    <div className="officials">
                        
                            
                        <div>
                                <div className="card-body ct-mid-card">
                                    <h5 className="card-title ct-card-title">Mr. G. UMACHANDAR</h5>
                                    <h6 className="card-subtitle mb-2 text-muted"> Partner</h6>
                                    <p className="card-text"> umachandar@unipel.in</p>
                                    <button className="ct-btn"><a href="mailto:umachandar@unipel.in" >Mail</a></button>
                                </div>
                        </div>

                      
                    </div>

                    

                    <hr className="ct-hr mb-4 mt-0 d-inline-block mx-auto" style={{width: "70%" ,height: "5px"}} />

                    
                    <div id="map-container-google-2" className="z-depth-1-half map-container ct-gmap" style={{height: "500px"}}>
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.1421467904547!2d80.13733751420068!3d12.962754618581169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525ff9683b4537%3A0xbf908cc2552cd815!2sUnipel%20Corporation!5e0!3m2!1sen!2sin!4v1594116215734!5m2!1sen!2sin" 
                            style={mapStyle} 
                            title="Unipel Corporation location"
                        >   
                        </iframe>
                    </div>
                </div>

                <Footer></Footer>
            </div>
        );
    }
}

export default Contact;