import React, { Component } from 'react';
import './cube.css';
import j1 from '../../Assets/Images/Home/1.jpg'
import j2 from '../../Assets/Images/Home/2.jpg'
import j3 from '../../Assets/Images/Home/3.jpg'
import j4 from '../../Assets/Images/Home/4.jpg'
import ivory from '../../Assets/Images/ivory.jpg'
class Cube extends Component{
    render(){
        return(

             <div className="rotating-box">
                <div className="single-rb">
                <div className="front-side">
                    <img src={j1} alt=""></img>
                </div>
                    <div className="back-side">
                        <img src={j3} alt=""></img>
                    </div>
                    <div className="left-side">
                        <img src={j4} alt=""></img>
                    </div>
                    <div className="right-side">
                        <img src={j2} alt=""></img>
                    </div>
                    <div className="top-side">
                        <img src={ivory} alt=""></img>
                    </div>
                    <div className="bottom-side">
                        <img src={j2} alt=""></img>
                    </div>
                </div>
             </div>
                          
        );
    }
}

export default Cube;