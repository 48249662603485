
import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Cube from '../../UI/Cube/cube'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import './Home.css'
import logo from '../../Assets/Images/Home/BSCI.png'



class Home extends Component{
  render(){
    return(
      <div>
        <Navbar></Navbar>
        <div className="home"> 
          {/* <Navbar></Navbar> */}
          <div className="brown-bg">
            <div className="contents">
              <div className="wrapper">
                <h1 className="welcome">Welcome <span>To</span></h1>
              </div>
              <div className="main-wrapper">
                <h1 className="unipel reveal-text">UNIPEL</h1>
                <h1 className="corporation reveal-text"><span>CORP</span>ORATION</h1>
              </div>
            </div>
            <div className="button_cont"><Link className="example_e" to="/about">EXPLORE</Link></div>

          </div>
          <div className="ivory-bg">
            <p className="about">Leather made since 2001</p>
            <Cube></Cube>
            <p className="about">A BSCI Certified Company</p>
            {/* <div className="button_cont"><Link class="example_e" to="/about" >EXPLORE</Link></div> */}
            <img className="BSCI" src={logo} alt=""></img>
          </div>
        </div>
        
       <Footer></Footer>
      </div>
    );
  }
}

export default Home;