import React, { Component } from 'react'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import './Events.css'
import Videocard from './Videocards'

const topFunction=()=> {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

const events=[["Fashion Show 2020","https://www.youtube.com/embed/tKodQKM4L18","Opening Sequence 2020","ITC Chennai"],
["Fashion Show 2019","https://www.youtube.com/embed/rOEVFvf8fxE","Grand Finale 2019","ITC Chennai"],
["Fashion Show 2018","https://www.youtube.com/embed/_8pTZYYyy6M"],
["Fashion Show 2017","https://www.youtube.com/embed/Y8a_DIzvL0I"],
["Fashion Show 2015","https://www.youtube.com/embed/g8rXDadkp6U"],
["Fashion Show 2014","https://www.youtube.com/embed/n90n6SJPI_4"],
["Fashion Show 2012","https://www.youtube.com/embed/glCF9l45AZA","Opening Sequence","Taj Coramandel Chennai"],
["Fashion Show 2011","https://www.youtube.com/embed/K6tkkHikVbg","Opening Sequence","Taj Coramandel Chennai"]
];
class Event extends Component{
    state={
        mainScreen:["Fashion Show 2020","https://www.youtube.com/embed/tKodQKM4L18/rel=0"]
    }
    clickHandler=(name,url)=>{
        this.setState( {mainScreen:[name,url]})
        topFunction();
    }
    render(){
        return(
            <div>
                <Navbar></Navbar>
                <div className="events">
                <div className="video-container">
                    <div className="main-video">
                            <h1 className="video-name">{this.state.mainScreen[0]}</h1>
                            <iframe className="jumbo-video" title="video-player" frameborder="0" allow="autoplay; encrypted-media" allowFullScreen src={this.state.mainScreen[1]} ></iframe>
                            {/* <div className="content-box">
                                <p className="video-text">Grand finale in year 2019<br></br>ITC chennai</p>
                            </div> */}
                    </div>
                    {events.map((event,index)=>{
                        if (event[0] !== this.state.mainScreen[0]){
                            return(
                                <Videocard key={index} title={event[0]} videoUrl={event[1]} about={event[2]} location={event[3]} opacity={1}Click={this.clickHandler}></Videocard>
                                );
                        }else{
                            return(
                                <Videocard key={index} title={event[0]} videoUrl={event[1]} about={event[2]} location={event[3]} opacity={0} Click={this.clickHandler}></Videocard>
                            );
                        }
                    })}
                </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Event;