import React, { Component} from 'react';
import {NavLink} from 'react-router-dom';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Navbar.css';
import logo from '../../Assets/Images/unipel logo.png';

const links=[['Home','/'],['About','/about'],['Our Brands','/brands'],['Gallery','/gallery'],['Events','/events'],['Contact Us','/contact']]

class Navbar extends Component{
    
    render(){

      const navlist=links.map((link,index)=>
      {
        return(
            <li key={index} className="nav-item ">
              <NavLink  exact activeClassName="active-link" className="nav-link" to={link[1]}>{link[0]}</NavLink>
            </li>
          );
      });

      return(
        <div className="nav-position">
          <nav className="navbar navbar-expand-md ">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <FontAwesomeIcon className="drop-button" icon={faBars}></FontAwesomeIcon>
            </button>
            <NavLink className="navbar-brand brand-img " to='/'>
              <img src={logo} alt=""></img>
            </NavLink>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                 {navlist}
              </ul>
            </div>

          </nav>
        </div>
      );
    }
  }
  
export default Navbar;
  