import React from 'react';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';

import classes from './CategoryItems.module.css';
import Navbar from '../../../UI/Navbar/Navbar';
import Footer from '../../../UI/Footer/Footer';
import OutlineButton from '../../../UI/OutlineBtn/OutlineBtn.js';
import Backdrop from '../../../UI/Backdrop/Backdrop.js';
import ImageCard from '../../../UI/ImageCard/ImageCard.js';
import FetchImages from './FetchImages.js';

const CategoryItems = (props) => {

	if(typeof(props.location.state)==='undefined')
		window.location.href = '/gallery'

	React.useEffect(()=>{
		document.body.scrollTop = 0;
	},[]);

	const [ showImg,setShowImg ] = React.useState(false);
	const [ zoomedImg,setZoomedImg ] = React.useState("");
	const images = FetchImages(props.location.state.dir);
  
	const btnStyles = {
		fontSize: '24px',
		color:'white',
		border: "none",
		borderRadius: '2px',
		borderBottom:'3px solid #eee',
		padding:'0 2%',
		textDecoration:'none'
	}

	const BackButton = () => (
		<div>
			<OutlineButton text=" < Go Back to Gallery" clicked={()=>props.history.push('/gallery')} style={btnStyles}/>  
		</div>
	);

	return (
		<div>
			<Navbar />
			<div className={classes.wrapper}>
				<div className={classes.headerContainer}>
					<h1 className={classes.header}>{props.location.state.name}</h1>
				</div>

				{showImg ? <Backdrop clicked={()=>setShowImg(false)} /> : null}
		        {showImg ? <ImageCard clicked={()=>setShowImg(false)} src={zoomedImg} alt="zoomed"/> : null}
				{
					Object.keys(images).map((k,index)=>(
						<LazyLoadImage 
							src={images[k]} 
							alt={""} 
							key={k} 
							className={classes.imgStyles} 
							onClick={()=>{
								setZoomedImg(images[k]);
								setShowImg(true);
							}}
							scrollPosition={props.scrollPosition}
						/>
					))
				}
				<BackButton />
			</div>
			<Footer />
		</div>
	);
};

export default trackWindowScroll(CategoryItems);