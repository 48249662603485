import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import Navbar from '../../UI/Navbar/Navbar';
import Footer from '../../UI/Footer/Footer';
import Card from '../../UI/Card/Card.js';
import classes from './Gallery.module.css';
import FetchImages from './CategoryItems/FetchImages.js';

const Gallery = (props) => {

    let { path } = useRouteMatch();

    let images = FetchImages('cards');
    let keys = Object.keys(images)
    let categories = [
        ["Fall Winter Men","fallmen",images[keys[0]]],
        ["Fall Winter Women","fallwomen",images[keys[1]]],
        ["Spring Summer Men","springmen",images[keys[2]]],
        ["Spring Summer Women","springwomen",images[keys[3]]]
    ];

    let cards = categories.map(([name,dir,img])=>(
        <Card 
            img={img} 
            name={name} 
            key={name} 
            clicked={()=>{
                props.history.push({
                    pathname:`${path}/${dir}`,
                    state:{
                        dir:dir,
                        name:name
                    }
                })
            }}
        />
    ));

    return(
        <div>
            <Navbar />
            <div className={classes.cardContainer}>
                { cards }
            </div>
            <Footer />
        </div>
    );

}

export default Gallery;