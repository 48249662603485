import React, { Component } from 'react'
import './Videocards.css'
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



class Videocard extends Component{
    sendData=()=>{
        this.props.Click(this.props.title,this.props.videoUrl)
    }
    render(){
        return(
            <div className={(this.props.opacity? "video-card-show" : "video-card-disable")} key={this.props.key}>
                <h1 className="video-titles">{this.props.title}</h1>
                <iframe  className="sub-videos "allowFullScreen src={this.props.videoUrl} title={this.props.key}  ></iframe>
                {this.props.about 
                &&  <div className="content-box">
                        <p className="video-text">{this.props.about}<br></br>{this.props.location}</p>
                    </div> }
                <button className="video-button" onClick={this.sendData}>Play!<FontAwesomeIcon icon={faPlay}/></button>
            </div>
            );
    }
}

export default Videocard;