import React, { Component } from 'react'
import './brandsCountry.css'


class brandsCountry extends Component{
    render(){
        // npm i babel-plugin-transform-react-jsx-img-import for auto img loading
        
        return Object.keys(this.props.images).map(
            (k,index)=>{
                return(
                    <img src={this.props.images[k]} alt="" key={k} className="brand-logo" />
                );
            }
        );
    }
}

export default brandsCountry;