import React, { Component } from 'react'
import BrandsCountry from './brandsCountry'
import './Country.css'

class Country extends Component{
    render(){
    var images=null;
    function importAll(r) {
      let images = {};
      r.keys().map((item, index) => { 
        return(images[item.replace('./', '')] = r(item)); 
      });
      return images;
    }
    switch(this.props.cname)
      {
        case "Italy":
          images = importAll(require.context('../../Assets/Images/Brands/Italy',false, /\.(png|jpe?g|svg)$/));
          break;
        case "Holland":
          images = importAll(require.context('../../Assets/Images/Brands/Holland',false, /\.(png|jpe?g|svg)$/));
          break;
        case "Spain":
          images = importAll(require.context('../../Assets/Images/Brands/Spain',false, /\.(png|jpe?g|svg)$/));
          break;
        case "Usa":
          images = importAll(require.context('../../Assets/Images/Brands/Usa',false, /\.(png|jpe?g|svg)$/));
          break;
        case "Germany":
            images = importAll(require.context('../../Assets/Images/Brands/Germany',false, /\.(png|jpe?g|svg)$/));
            break;
        default:
          console.log("Error in getting image")      
      }        
        return(
          <div className="main-country">
            <div className="country-name">
                <h1 className="country">{this.props.cname}</h1>
                <BrandsCountry images={images} key={this.props.key} />
            </div>
            </div>
        );
    }
}

export default Country;