import React, { Component } from 'react'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import './Brand.css'
import Country from './Country' 

class Brands extends Component{     
  componentDidMount() {
    window.scrollTo(0, 0)
  }
    render(){
        var countries=["Italy","Spain",'Holland','Germany','Usa']
        return(   
          <div>
            <Navbar></Navbar>
            <div className="brand">
                <div className='align'>
                <h1 className="brand-title">We have been associated with many of the fashion brands across the globe</h1>
                </div>
                <div className="post-container">
                {countries.map((country,index)=>(
                      <Country cname={country} key={index} />
                  )
                )}
                </div>
                <Footer></Footer>
            </div>
          </div>
            
        );
    }
}

export default Brands;
