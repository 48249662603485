import React from 'react';

import classes from './Card.module.css';
import OutlineButton from '../OutlineBtn/OutlineBtn';

const Card = (props) => {

	const [ load,setLoad ] = React.useState(true);
	return (
		<div className={classes.cardStyles} style={{visibility:load?'hidden':'visible'}}>
			<div className={classes.imgContainer}>
				<img src={props.img} className={classes.cardImg} alt={props.name} onLoad={()=>setLoad(false)} />
			</div>
			<div className={classes.cardContent}>
				<div className={classes.textStyles}>{props.name}</div>
				<OutlineButton text="Click to View" clicked={props.clicked}/>
			</div>		
		</div>
	);
};

export default Card;
